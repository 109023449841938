import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Loading from "@components/shared/Loading";
import Heading from "@components/layout/Heading";
import WaterClassSummary from "@components/form/subscriber/dashboard/level0accounts/WaterClassSummary";
import { useSubscriberLevel0Resource } from "@hooks/query/zenith/useSubscriberLevel0Resource";
import { useAppContext } from "@context/AppContext";
import { formatVolume } from "@utils/formatVolume";
import {
  extractionRightTypes,
  getExtractionRightTypeIdentifier,
} from "@services/extractionRight";
import { useGetSubscriber } from "@hooks/query/zenith/useGetSubscriber";

const Level0WRSSummaryTab: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { setInfoPanel, loggedInInfo } = useAppContext();
  const { id: level0ResourceId = "" } = useParams();
  const { isLoading: isSubscriberLoading } = useGetSubscriber({
    id: loggedInInfo?.userDetails?.subscriberId,
    options: { enabled: Boolean(loggedInInfo?.userDetails?.subscriberId) },
  });
  const { data: level0Resource = {}, isLoading: isLevel0ResourceLoading } =
    useSubscriberLevel0Resource({
      params: {
        level0ResourceId,
      },
    });

  React.useEffect(() => {
    setInfoPanel();
  }, [setInfoPanel]);

  if (isSubscriberLoading || isLevel0ResourceLoading) {
    return (
      <div className="pt-20">
        <Loading />
      </div>
    );
  }

  const waterClasses = level0Resource?.waterClasses ?? [];
  const otherUsages: { label: string; usage: number; show: boolean }[] = [
    {
      label: t("approval.stock_and_domestic.title"),
      usage: level0Resource?.stockAndDomesticUsage,
      show: level0Resource?.otherClasses?.some(
        (oc: { type: string }) =>
          oc.type === getExtractionRightTypeIdentifier(extractionRightTypes.SD),
      ),
    },
    {
      label: t("declaration.water_harvesting"),
      usage: level0Resource?.waterHarvestingUsage,
      show: level0Resource?.otherClasses?.some(
        (oc: { type: string }) =>
          oc.type === getExtractionRightTypeIdentifier(extractionRightTypes.WH),
      ),
    },
    {
      label: t("subscriber.special_announcement"),
      usage: level0Resource?.specialAnnouncementUsage,
      show: level0Resource?.otherClasses?.some(
        (oc: { type: string }) =>
          oc.type === getExtractionRightTypeIdentifier(extractionRightTypes.SA),
      ),
    },
  ];

  return (
    <div className="space-y-6">
      <header className="space-y-3">
        <Heading>
          {t("subscriber.level0wrs_accounts")}: {level0Resource?.identifier}
        </Heading>
      </header>

      {waterClasses.length <= 0 && (
        <div className="text-center space-y-6">
          <p className="whitespace-nowrap text-gray-400 text-center">
            {t("extraction_right.no_data")}
          </p>
          {/* <Link */}
          {/*   className="inline-block btn-secondary" */}
          {/*   to={`/polestar/level1wrs/${level1ResourceId}/extraction_rights/create?subscriberId=${loggedInInfo?.userDetails?.subscriberId}&level0ResourceId=${level0ResourceId}`} */}
          {/* > */}
          {/*   {t("extraction_right.create.title")} */}
          {/* </Link> */}
        </div>
      )}

      {waterClasses.length > 0 && (
        <div className="grid grid-cols-1 gap-6 2xl:grid-cols-10">
          <div className="col-span-7">
            <div className="space-y-6">
              {waterClasses.map((i: any) => {
                return (
                  <WaterClassSummary
                    key={i.id}
                    name={i.name}
                    extractionRightNames={i.extractionRights
                      ?.filter(
                        (i: any) =>
                          i.extractionRightTypeId !== extractionRightTypes.PT,
                      )
                      ?.map((i: any) => i.name)}
                    nominalVolume={i.volume}
                    announcedAllocation={i.balance + i.usage + -1 * i.transfer}
                    allocationUsage={i.usage + i.overusedBalance}
                    transfer={i.transfer}
                    specialAnnouncement={i.specialAnnouncementUsage}
                    forwardDraw={0}
                    rate={i.rate * 100}
                  />
                );
              })}
            </div>
          </div>

          <aside className="col-span-3">
            <div className="rounded-t-lg relative overflow-hidden">
              <header
                className="px-4 py-3"
                style={{
                  backgroundColor: "#7ECCC2",
                }}
              >
                <Heading light className="md:text-xl">
                  {t("subscriber.volume_other")}
                </Heading>
              </header>
              <ul className="p-4 space-y-2 border border-t-0">
                {otherUsages
                  .filter(i => i.show)
                  .map(i => (
                    <li
                      key={"otherUsage--" + i.label}
                      className="flex justify-between"
                    >
                      <span className="text-gray-500">{i.label}</span>
                      <span className="text-lg text-primary-2 font-bold">
                        {formatVolume(i.usage)}
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
          </aside>
        </div>
      )}
    </div>
  );
};

export default Level0WRSSummaryTab;
