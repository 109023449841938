import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import Heading from "@components/layout/Heading";
import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import { formatDate } from "@utils/formatDate";

type CreateExchangeRateStep2Props = {
  rate: number;
  startAt: Date;
  level0Resources: any;
  onSave: (data: { rate: number; startAt: Date }) => void;
  onCancel: () => void;
  selectedYear: any;
};

const CreateExchangeRateStep2: React.FunctionComponent<
  CreateExchangeRateStep2Props
> = ({ rate, startAt, level0Resources, onSave, onCancel, selectedYear }) => {
  const { t } = useTranslation();
  const [selectedRate, setSelectedRate] = useState<number>(rate || 0);
  const [selectedRatePercentage, setSelectedRatePercentage] = useState<number>(
    rate ? rate * 100 : 0,
  );
  const [selectedStartAt, setSelectedStartAt] = useState(
    startAt || new Date(selectedYear.periodStart),
  );
  const [minRate, setMinRate] = useState(0);

  const handleSave = (e: any) => {
    e.preventDefault();

    onSave({
      rate: selectedRate,
      startAt: selectedStartAt,
    });
  };

  useEffect(() => {
    if (level0Resources) {
      let minRate = 0;

      level0Resources.forEach((zone: any) => {
        zone.selectedWaterClasses?.forEach((waterClass: any) => {
          const lastAnnouncement = waterClass.exchangeRates
            ?.filter((exchangeRate: any) => {
              return exchangeRate?.accountingPeriodId === selectedYear?.id;
            })
            ?.sort(
              (a: any, b: any) =>
                new Date(b.createdAt).valueOf() -
                new Date(a.createdAt).valueOf(),
            )[0];

          if (lastAnnouncement.rate > minRate) {
            minRate = lastAnnouncement.rate;
          }
        });
      });

      setMinRate(minRate);
    }
  }, [level0Resources, selectedYear?.id]);

  return (
    <form className="space-y-8" onSubmit={handleSave}>
      <div className="p-6">
        <div>
          <Heading className="text-xl leading-6" light={true}>
            {t("exchange_rate.enter_values")}
          </Heading>
        </div>
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-4">
            <Label htmlFor="exchangeRate">
              {t("exchange_rate.enter_rate")}
            </Label>
            <TextInput
              id="exchangeRate"
              type="number"
              min={minRate * 100}
              max={100}
              value={selectedRatePercentage.toString()}
              onChange={e => {
                const value = parseFloat(e.target.value);
                setSelectedRatePercentage(value);
                setSelectedRate(value / 100);
              }}
            />
            <p className="mt-2 text-sm text-gray-400">
              {t("exchange_rate.enter_rate_description")}
            </p>
          </div>
          <div className="sm:col-span-4">
            <Label htmlFor="effectiveAt">
              {t("exchange_rate.enter_start_at")}
            </Label>
            <TextInput
              id="effectiveAt"
              type="date"
              value={formatDate(selectedStartAt, "yyyy-MM-dd")}
              max={formatDate(new Date(selectedYear.periodEnd), "yyyy-MM-dd")}
              min={formatDate(new Date(selectedYear.periodStart), "yyyy-MM-dd")}
              onChange={e => {
                setSelectedStartAt(new Date(e.target.value));
              }}
              defaultValue={formatDate(
                new Date(selectedYear.periodStart),
                "yyyy-MM-dd",
              )}
            />
          </div>
        </div>
      </div>
      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={onCancel}
        >
          {t("common.prev_step")}
        </button>
        <button
          type="submit"
          className="btn-primary"
          disabled={_.isNaN(selectedRate) || !selectedStartAt}
        >
          {t("common.next_step")}
        </button>
      </footer>
    </form>
  );
};

export default CreateExchangeRateStep2;
