import React from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

import Label from "@components/form/Label";
import TextArea from "@components/form/TextArea";
import TimestampInput from "@components/form/TimestampInput";
import ApplicationActionButtons from "@components/form/ApplicationActionButtons";
import Modal from "@components/layout/Modal";
import Tag from "@components/shared/Tag";
import ConfirmModal from "@components/shared/ConfirmModal";
import EvidenceList from "@components/shared/EvidenceList";
import { useUpdateStockAndDomestic } from "@hooks/mutation/useUpdateStockAndDomestic";
import { formatVolume } from "@utils/formatVolume";
import { formatAccountingPeriod } from "@utils/formatAccountingPeriod";
import { AdministrativeApprovalNumericStatus } from "@services/administrativeApprovals";
import { toastError, toastSuccess } from "@utils/toast";
import {
  getActionTimestamp,
  getStatusAndText,
  getActionByUser,
} from "@utils/administrativeApproval";
import { formatDate, formatDateInput } from "@utils/formatDate";

type StockAndDomesticModalProps = {
  data?: any;
  onClose: () => void;
  actionButtons?: React.ReactNode;
};

const StockAndDomesticModal: React.FunctionComponent<
  StockAndDomesticModalProps
> = ({ data, onClose, actionButtons }) => {
  const { t } = useTranslation();
  const [confirmationModal, setConfirmationModal] = React.useState<
    "acknowledge" | "reject" | "request_info"
  >();
  const [effectiveDate, setEffectiveDate] = React.useState("");
  const [note, setNote] = React.useState("");
  const { mutateAsync: updateStockAndDomestic, isLoading: isSubmitting } =
    useUpdateStockAndDomestic();

  const isApproveModal = confirmationModal === "acknowledge";
  const isRejectModal = confirmationModal === "reject";
  const isRequestInfoModal = confirmationModal === "request_info";

  const { status: statusTag = "default", text: statusText } = getStatusAndText(
    data?.status,
  );

  const handleSave = async () => {
    try {
      const status = isApproveModal
        ? AdministrativeApprovalNumericStatus.Approved
        : isRejectModal
          ? AdministrativeApprovalNumericStatus.Declined
          : isRequestInfoModal
            ? AdministrativeApprovalNumericStatus.UnderReview
            : AdministrativeApprovalNumericStatus.Pending;

      await updateStockAndDomestic({
        id: data.id,
        status,
        effectiveDate,
        note,
      });

      const message = isApproveModal
        ? t("approval.stock_and_domestic.toast.approve")
        : isRejectModal
          ? t("approval.stock_and_domestic.toast.rejected")
          : isRequestInfoModal
            ? t("approval.stock_and_domestic.toast.req_info")
            : "";

      toastSuccess(message);
    } catch (error: any) {
      toastError(
        t("approval.stock_and_domestic.toast.error", {
          error: error?.response?.data?.message || error?.message,
        }),
      );
    }
    setConfirmationModal(undefined);
    onClose();
  };

  const handleCancelConfirmation = () => {
    setNote("");
    setEffectiveDate("");
    setConfirmationModal(undefined);
  };

  const extractionRight = data?.extractionRights?.[0];

  const details = [
    {
      label: t("common.level1wrs"),
      value: data?.level1Resource.name,
      show: data?.level1Resource.name,
    },
    {
      label: t("common.extraction_right"),
      value: extractionRight?.name ?? "-",
      show: true,
    },
    {
      label: t("extraction_right.volume"),
      value: formatVolume(
        extractionRight?.volume ? +extractionRight?.volume : 0,
      ),
      show: true,
    },
    {
      label: t("common.level0wrs"),
      value: extractionRight?.level0Resource?.identifier,
      show: true,
    },
    {
      label: t("common.water_class"),
      value: extractionRight?.waterClass?.name ?? "-",
      show: true,
    },
    {
      label: t("common.volume"),
      value: formatVolume(data?.volume),
      show: true,
    },
    {
      label: t("common.accounting_period"),
      value: data?.accountingPeriod
        ? formatAccountingPeriod(data?.accountingPeriod)
        : "-",
      show: data?.accountingPeriod,
    },
    {
      label: t("common.effective_date"),
      value: data?.effectiveDate
        ? formatDate(new Date(data.effectiveDate))
        : "-",
      show: Boolean(data?.effectiveDate),
    },
    {
      label: t("common.status"),
      value: <Tag status={statusTag}>{statusText}</Tag>,
      show: true,
    },
    {
      label: t("common.note"),
      value: data?.note ?? "-",
      show: true,
    },
    {
      label: t("common.actioned_at"),
      value: getActionTimestamp(data),
      show: true,
    },
    {
      label: t("common.actioned_by"),
      value: getActionByUser(data)?.name ?? "-",
      show: true,
    },
  ];

  const subscribers = [
    {
      id: data?.buyer?.id,
      title: t("common.customer"),
      body: [
        {
          label: t("subscriber.name"),
          value: data?.buyer?.name,
        },
        {
          label: t("subscriber.account_number"),
          value: data?.buyer?.accountNumber,
        },
      ],
    },
  ];

  const confirmModals: {
    open: boolean;
    confirmText: string;
    description: React.ReactNode;
  }[] = [
    {
      open: isApproveModal,
      confirmText: t("common.ledger"),
      description: (
        <div className="space-y-4">
          <div>
            {t("approval.seasonal_water_assignments.modal.confirm.approve")}
          </div>
          <div className="font-normal text-gray-700">
            <TimestampInput
              type="date"
              id="effectiveDate"
              level1ResourceId={data?.level1ResourceId}
              value={effectiveDate && formatDateInput(new Date(effectiveDate))}
              onChange={e => setEffectiveDate(e.target.value)}
              required
            />
          </div>
          <div className="font-normal text-gray-700">
            <Label htmlFor="note" optional>
              {t("common.note")}
            </Label>
            <TextArea
              id="note"
              className="text-sm"
              placeholder={t("common.note") as string}
              onChange={e => setNote(e.target.value)}
              rows={4}
            />
          </div>
        </div>
      ),
    },
    {
      open: isRejectModal,
      confirmText: t("common.reject"),
      description: (
        <>
          {t("approval.stock_and_domestic.modal.confirm.reject")}
          <div className="mt-4 font-normal text-gray-700">
            <Label>{t("common.reason_for_rejection")}</Label>
            <TextArea
              className="text-sm"
              placeholder={t("common.write_info_placeholder") as string}
              onChange={e => setNote(e.target.value)}
              rows={4}
              required
            />
          </div>
        </>
      ),
    },
    {
      open: isRequestInfoModal,
      confirmText: t("common.request"),
      description: (
        <>
          {t("common.request_more_info")}
          <div className="mt-4 font-normal text-gray-700">
            <Label htmlFor="requestInfo">
              {t("common.request_info_query")}
            </Label>
            <TextArea
              id="requestInfo"
              className="text-sm"
              placeholder={t("common.write_info_placeholder") as string}
              onChange={e => setNote(e.target.value)}
              rows={4}
              required
            />
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <Modal open={data && !confirmationModal}>
        <div className="flex flex-col">
          <div className="border-b p-4">
            <h3 className="text-lg font-bold leading-6 text-gray-900">
              {t("approval.stock_and_domestic.title")}
            </h3>
          </div>
          <div className="p-4">
            <dl className="grid grid-cols-2 gap-x-4 gap-y-2 sm:grid-cols-1 mb-4">
              {details.map(i =>
                i.show ? (
                  <div
                    className="col-span-2 sm:col-span-1 flex gap-1"
                    key={`${i.label}--details`}
                  >
                    <dt>{i.label}:</dt>
                    <dd className="text-gray-500">{i.value}</dd>
                  </div>
                ) : null,
              )}
            </dl>

            {subscribers.map(s => (
              <a
                key={s.id}
                href={`/polestar/subscribers/${s.id}`}
                className="group border rounded-lg p-2 flex flex-col gap-3"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h3 className="flex flex-row items-center gap-1.5 font-semibold">
                  {s.title}
                  <ArrowTopRightOnSquareIcon className="w-4 h-4 opacity-50 group-hover:opacity-100 transition-opacity" />
                </h3>{" "}
                <dl className="text-sm grid grid-cols-2 gap-x-4 gap-y-2 sm:grid-cols-1">
                  {s.body.map(i => (
                    <div
                      className="col-span-2 sm:col-span-1 flex gap-1"
                      key={i.label}
                    >
                      <dt>{i.label}:</dt>
                      <dd>{i.value}</dd>
                    </div>
                  ))}
                </dl>
              </a>
            ))}
          </div>

          {data?.evidences.length ? (
            <EvidenceList data={data.evidences} />
          ) : null}

          {actionButtons ? (
            <div className="flex justify-end gap-3 p-4 border-t text-sm">
              {actionButtons}
            </div>
          ) : (
            <ApplicationActionButtons
              status={data?.status}
              onApprove={() => setConfirmationModal("acknowledge")}
              onCancel={() => {
                setConfirmationModal(undefined);
                onClose();
              }}
              onReject={() => setConfirmationModal("reject")}
              onRequireMoreInfo={() => setConfirmationModal("request_info")}
            />
          )}
        </div>
      </Modal>

      {confirmModals.map((i, index) => (
        <ConfirmModal
          key={`confirm-modal-${index}`}
          open={i.open}
          confirmText={i.confirmText}
          onConfirm={handleSave}
          onClose={handleCancelConfirmation}
          isSubmitting={isSubmitting}
        >
          {i.description}
        </ConfirmModal>
      ))}
    </>
  );
};

export default StockAndDomesticModal;
