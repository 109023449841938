import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import Label from "@components/form/Label";
import RadioInput from "@components/form/RadioInput";
import { usePermanentTransferContext } from "@context/PermanentTransferContext";

type EnterDetailsProps = {
  onNext: () => void;
  onPrev: () => void;
  onCancel: () => void;
};

const EnterDetails: React.FunctionComponent<EnterDetailsProps> = ({
  onNext,
  onPrev,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { usageIn, setUsageIn } = usePermanentTransferContext();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onNext();
  };

  return (
    <form
      className="flex flex-col p-6 grow justify-between"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col grow gap-6">
        <Heading light>
          {t("approval.permanent_trades.enter_details.title")}
        </Heading>

        <fieldset className="space-y-6">
          <div>
            <Label>
              {t("approval.seasonal_water_assignments.create.step_5.usage_in")}
            </Label>
            <div className="space-y-3">
              {["Irrigation", "Non-irrigation"].map(value => {
                return (
                  <RadioInput
                    key={value}
                    htmlFor="zeroValueTradeReason"
                    label={value}
                    checked={usageIn === value}
                    onChange={() => {
                      setUsageIn(value);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </fieldset>
      </div>

      <footer className="flex gap-4 -mx-6 mt-6 p-6 pb-0 border-t border-gray-200">
        <button type="button" className="btn-outline-primary" onClick={onPrev}>
          {t("common.prev_step")}
        </button>

        <button type="submit" className="btn-primary">
          {t("common.next_step")}
        </button>

        <button
          type="button"
          className="btn-outline-primary"
          onClick={onCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default EnterDetails;
