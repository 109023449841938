import { Outlet, NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Popover } from "@headlessui/react";
import {
  Squares2X2Icon,
  ForwardIcon,
  LockClosedIcon,
} from "@heroicons/react/24/solid";
import { twMerge } from "tailwind-merge";

import Layout from "@components/layout/Layout";
import Loading from "@components/shared/Loading";
import Tag from "@components/shared/Tag";
import ZoneAccountIcon from "@components/icons/ZoneAccountIcon";
import Alert from "@components/shared/Alert";
import { useSubscriber } from "@hooks/query/useSubscriber";
import { useAllSubscriberLevel0Resources } from "@hooks/query/useAllSubscriberLevel0Resources";

type Menu = {
  label: any;
  icon?: React.ReactNode;
  href: string;
  subMenus?: Menu[];
};

const SubscriberDashboardLayout = () => {
  const { t } = useTranslation();
  const { id: subscriberId = "", level0ResourceId = "" } = useParams();
  const { data: subscriber = {}, isLoading } = useSubscriber(subscriberId);
  const { data: level0Resources = [], isLoading: isLevel0ResourcesLoading } =
    useAllSubscriberLevel0Resources(subscriberId);

  if (isLoading || isLevel0ResourcesLoading) {
    return (
      <div className="pt-20">
        <Loading />
      </div>
    );
  }

  const menus: Menu[] = [
    {
      label: t("subscriber.dashboard"),
      icon: <Squares2X2Icon />,
      href: `/polestar/subscribers/${subscriberId}`,
    },
    {
      label: t("subscriber.level0wrs"),
      icon: <ZoneAccountIcon />,
      href: `/polestar/subscribers/${subscriberId}/level0_resources`,
      subMenus: level0Resources.map((i: any) => {
        return {
          label: i.identifier,
          href: `/polestar/subscribers/${subscriberId}/level0_resources/${i.id}`,
        };
      }),
    },
    {
      label: t("common.audit_trail_and_evidence"),
      icon: <ForwardIcon />,
      href: `/polestar/subscribers/${subscriberId}/audit_trails`,
    },
  ];

  const hasWaterClasses = level0Resources.some(
    (i: any) => i?.waterClasses?.length > 0,
  );
  const hasUsage = level0Resources.some((i: any) => i.usage > 0);
  const hasAA = level0Resources.some((i: any) =>
    i.waterClasses.some((w: any) => w.rate > 0),
  );
  const reachAALimit =
    hasWaterClasses &&
    level0Resources.every((i: any) => i.balance === 0) &&
    (hasAA || hasUsage);

  return (
    <Layout
      permissions={["ViewSubscribers"]}
      accountingPeriodLevel1ResourceId={subscriber?.level1ResourceId}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: t("common.subscribers"),
          href: "/polestar/subscribers",
        },
        {
          label: `${subscriber?.name}`,
        },
      ]}
      title={`${subscriber?.name} ${
        subscriber?.accountNumber ? `(${subscriber.accountNumber})` : ""
      }`}
      status={
        <Tag status={subscriber?.isActive ? "success" : "error"}>
          {subscriber?.isActive ? t("common.active") : t("common.inactive")}
        </Tag>
      }
      moreInfo={
        <Popover className="static">
          <Popover.Button className="text-sm text-primary-2 underline outline-none">
            {t("common.more_info")}
          </Popover.Button>

          <Popover.Overlay className="absolute z-10">
            <div className="flex justify-center items-center h-min bg-gray-300">
              <div className="max-w-sm rounded shadow-lg bg-white">
                <div className="relative flex justify-left">
                  <div className="bg-white absolute w-3 h-3 transform rotate-45 -mt-1"></div>
                </div>
                <div className="px-6 py-4 text-sm">
                  <p className="text-gray-700">
                    <span className="pr-1 font-bold">
                      {t("common.level1wrs")}:
                    </span>
                    {subscriber?.level1WRS?.name}
                  </p>
                  <p className="text-gray-700">
                    <span className="pr-1 font-bold">
                      {t("common.address")}:
                    </span>
                    {subscriber?.address ? (
                      `${subscriber.address.street}, ${subscriber.address.town}`
                    ) : (
                      <span className="pl-10">-</span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </Popover.Overlay>
        </Popover>
      }
    >
      {hasWaterClasses && !hasAA && (
        <Alert type="error" className="mb-4">
          {t("subscriber.allocation_not_done")}
        </Alert>
      )}

      {reachAALimit && (
        <Alert type="error" className="mb-4">
          <span>{t("subscriber.balance_exhausted")}</span>
          {false && (
            <button
              type="button"
              className="btn-default border-red-700 bg-red-800 text-red-200 text-sm hover:text-red-500 inline-flex items-center gap-1"
            >
              <LockClosedIcon className="w-4" />
              {t("subscriber.lock_users")}
            </button>
          )}
        </Alert>
      )}

      <div className="flex grow gap-4">
        <aside className="w-70 shrink-0">
          <ul className="border divide-y bg-white">
            {menus.map(i => (
              <li key={i.label}>
                <NavLink
                  to={i.href}
                  className={({ isActive }) =>
                    twMerge(
                      "flex flex-row gap-4 p-4 cursor-pointer",
                      isActive
                        ? "border-r-4 border-r-primary-2 bg-blue-50 text-primary-3 font-semibold"
                        : "text-primary-3 opacity-70",
                    )
                  }
                  end={!i.subMenus?.length}
                >
                  <span className="inline-block w-5 h-5 shrink-0">
                    {i.icon}
                  </span>
                  {i.label}
                </NavLink>

                {Boolean(level0ResourceId) &&
                  i.subMenus?.map(sub => {
                    return (
                      <NavLink
                        key={sub.label}
                        to={sub.href}
                        className={({ isActive }) =>
                          twMerge(
                            "pl-12 pt-4 last-of-type:pb-4 flex flex-row gap-2 items-center bg-blue-50",
                            isActive
                              ? "font-semibold cursor-default"
                              : "text-primary-3 opacity-70 hover:opacity-100 transition-opacity",
                          )
                        }
                      >
                        <span className="text-xs">&#8226;</span>
                        {sub.label}
                      </NavLink>
                    );
                  })}
              </li>
            ))}
          </ul>
        </aside>

        <div className="flex grow">
          <Outlet />
        </div>
      </div>
    </Layout>
  );
};

export default SubscriberDashboardLayout;
