import React from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

import Modal from "@components/layout/Modal";
import TextArea from "@components/form/TextArea";
import Label from "@components/form/Label";
import Tag from "@components/shared/Tag";
import ApplicationActionButtons from "@components/form/ApplicationActionButtons";
import ConfirmModal from "@components/shared/ConfirmModal";
import EvidenceList from "@components/shared/EvidenceList";
import { formatVolume } from "@utils/formatVolume";
import { formatAccountingPeriod } from "@utils/formatAccountingPeriod";
import {
  getStatusAndText,
  getActionTimestamp,
  getActionByUser,
} from "@utils/administrativeApproval";
import { toastError, toastSuccess } from "@utils/toast";
import { formatDate, formatDateInput } from "@utils/formatDate";
import { formatPrice } from "@utils/formatPrice";
import {
  type BillingGroup,
  useAuditPermanentTransfer,
} from "@hooks/mutation/useAuditPermanentTransfer";
import TimestampInput from "@components/form/TimestampInput";
import SelectBillingGroup from "@components/form/SelectBillingGroup";
import SelectItemNo from "@components/form/SelectItemNo";

type PermanentTransferModalProps = {
  data?: any;
  onClose: () => void;
  actionButtons?: React.ReactNode;
};

const PermanentTransferModal: React.FunctionComponent<
  PermanentTransferModalProps
> = ({ data, actionButtons, onClose }) => {
  const { t } = useTranslation();
  const [confirmationModal, setConfirmationModal] = React.useState<
    "acknowledge" | "reject" | "request_info"
  >();
  const [note, setNote] = React.useState("");
  const [effectiveDate, setEffectiveDate] = React.useState("");
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [billingGroups, setBillingGroups] = React.useState<BillingGroup[]>(
    () => {
      return data?.extractionRights?.map((i: any) => {
        return {
          id: i.billingGroupId,
          extractionRightId: i.id,
          itemNo: i.itemNo,
        };
      });
    },
  );

  const { mutateAsync: auditPermanentTransfer } = useAuditPermanentTransfer();

  const isApproveModal = confirmationModal === "acknowledge";
  const isRejectModal = confirmationModal === "reject";
  const isRequestInfoModal = confirmationModal === "request_info";

  const isValidBillings = billingGroups?.every(
    i => Boolean(i.id) && Boolean(i.itemNo),
  );

  const handleSave = async () => {
    setIsSubmitting(true);
    try {
      let message = "";
      if (isApproveModal) {
        await auditPermanentTransfer({
          id: data.id,
          effectiveDate,
          note,
          billingGroups,
          action: "approve",
        });
        message = t("approval.permanent_trades.toast.approve");
      }
      if (isRejectModal) {
        await auditPermanentTransfer({
          id: data.id,
          note,
          action: "reject",
        });
        message = t("approval.permanent_trades.toast.rejected");
      }
      if (isRequestInfoModal) {
        await auditPermanentTransfer({
          id: data.id,
          note,
          action: "requestInfo",
        });
        message = t("approval.permanent_trades.toast.req_info");
      }

      toastSuccess(message);
    } catch (e: any) {
      toastError(t("approval.permanent_trades.toast.error"));
    }
    setConfirmationModal(undefined);
    onClose();
    setIsSubmitting(false);
  };

  const handleCancelConfirmation = () => {
    setNote("");
    setEffectiveDate("");
    setConfirmationModal(undefined);
  };

  const { status: tagStatus = "default", text: statusText } = getStatusAndText(
    data?.status,
  );

  const confirmModals = [
    {
      open: isApproveModal,
      confirmText: t("common.ledger") as string,
      description: (
        <div className="space-y-4">
          <h5>{t("approval.permanent_trades.modal.confirm.approval")}</h5>
          {data?.extractionRights?.map((right: any) => {
            const currentBilling = billingGroups.find(
              (billing: any) => billing.extractionRightId === right.id,
            );

            if (!currentBilling) {
              return null;
            }

            return (
              <div
                key={right.id}
                className="space-y-2 font-normal text-gray-700"
              >
                <h4 className="text-base">
                  {t("extraction_right.name")}: {right.name}
                </h4>
                <div className="text-sm">
                  <Label htmlFor={`billing--${right.id}`}>
                    {t("extraction_right.create.step_2.billing_group")}
                  </Label>
                  <SelectBillingGroup
                    inputId={`billing--${right.id}`}
                    level1ResourceId={data?.level1ResourceId}
                    value={currentBilling.id}
                    onChange={(e: any) => {
                      if (e?.value) {
                        setBillingGroups(prev => {
                          return prev.map(billing => {
                            if (billing.extractionRightId === right.id) {
                              return {
                                ...billing,
                                id: e.value,
                                itemNo: "",
                              };
                            }
                            return billing;
                          });
                        });
                      }
                    }}
                  />
                </div>
                <div className="text-sm">
                  <Label htmlFor={`itemNo--${right.id}`}>
                    {t("extraction_right.create.step_2.item_number")}
                  </Label>
                  <SelectItemNo
                    inputId={`itemNo--${right.id}`}
                    billingGroupId={currentBilling.id}
                    value={currentBilling.itemNo}
                    onChange={(e: any) => {
                      if (e?.value) {
                        setBillingGroups(prev => {
                          return prev.map(billing => {
                            if (billing.extractionRightId === right.id) {
                              return {
                                ...billing,
                                itemNo: e.value,
                              };
                            }
                            return billing;
                          });
                        });
                      }
                    }}
                  />
                </div>
              </div>
            );
          })}
          <div className="font-normal text-gray-700">
            <TimestampInput
              type="date"
              id="effectiveDate"
              level1ResourceId={data?.level1ResourceId}
              value={effectiveDate && formatDateInput(new Date(effectiveDate))}
              onChange={e => setEffectiveDate(e.target.value)}
              required
            />
          </div>
          <div className="font-normal text-gray-700">
            <Label htmlFor="note" optional>
              {t("common.write_approve_info")}
            </Label>
            <TextArea
              id="note"
              className="text-sm"
              onChange={e => setNote(e.target.value)}
              rows={4}
            />
          </div>
        </div>
      ),
    },
    {
      open: isRejectModal,
      confirmText: t("common.reject") as string,
      description: (
        <>
          {t("approval.permanent_trades.modal.confirm.reject")}
          <div className="mt-4 font-normal text-gray-700">
            <Label htmlFor="note">{t("common.reason_for_rejection")}</Label>
            <TextArea
              id="note"
              className="text-sm"
              placeholder={t("common.write_info_placeholder") as string}
              onChange={e => setNote(e.target.value)}
              rows={4}
              required
            />
          </div>
        </>
      ),
    },
    {
      open: isRequestInfoModal,
      confirmText: t("common.request") as string,
      description: (
        <>
          {t("common.request_more_info")}
          <div className="mt-4 font-normal text-gray-700">
            <Label htmlFor="note">{t("common.request_info_query")}</Label>
            <TextArea
              id="note"
              className="text-sm"
              placeholder={t("common.write_info_placeholder") as string}
              onChange={e => setNote(e.target.value)}
              rows={4}
              required
            />
          </div>
        </>
      ),
    },
  ];

  const details = [
    {
      label: t("common.level1wrs"),
      value: data?.level1Resource.name,
      show: data?.level1Resource.name,
    },
    {
      label: t("common.level0wrs"),
      value: data?.extractionRights[0]?.level0Resource?.identifier ?? "-",
      show: true,
    },
    {
      label: t("common.extraction_rights"),
      value: data?.extractionRights
        ?.map(
          (i: any) =>
            `${i.name} ${i.waterClass?.name} (${formatVolume(+i.volume)})`,
        )
        ?.join(", "),
      show: data?.extractionRights?.length,
    },
    {
      label: t("common.extraction_points"),
      value: data?.extractionPoints?.map((i: any) => i.name)?.join(", "),
      show: data?.extractionPoints?.length,
    },
    {
      label: t("approval.permanent_trades.h2w"),
      value: data?.extractionRights
        ?.map(
          (i: any) =>
            `${i.name} (${formatVolume(i.physicalWater ? Number(i.physicalWater) : 0)})`,
        )
        .join(", "),
      show: data?.volume,
    },
    {
      label: t("common.accounting_period"),
      value: data?.accountingPeriod
        ? formatAccountingPeriod(data.accountingPeriod)
        : "-",
      show: data?.accountingPeriod,
    },
    {
      label: t("common.price"),
      value: formatPrice(data?.price ?? 0),
      show: data?.volume > 0,
    },
    {
      label: t("approval.seasonal_water_assignments.reason_for_zero_trade"),
      value: data?.description ?? "-",
      show: data?.volume > 0 && data?.price === 0,
    },
    {
      label: t("common.status"),
      value: <Tag status={tagStatus}>{statusText}</Tag>,
      show: true,
    },
    {
      label: t("common.note"),
      value: data?.note ?? "-",
      show: data?.note,
    },
    {
      label: t("common.effective_date"),
      value: data?.effectiveDate
        ? formatDate(new Date(data.effectiveDate))
        : "-",
      show: data?.effectiveDate,
    },
    {
      label: t("common.actioned_at"),
      value: getActionTimestamp(data),
      show: true,
    },
    {
      label: t("common.actioned_by"),
      value: getActionByUser(data)?.name ?? "-",
      show: true,
    },
  ];

  const subscribers = [
    {
      id: data?.seller.id,
      title: t("approval.seasonal_water_assignments.from_subscriber"),
      body: [
        {
          label: t("subscriber.name"),
          value: data?.seller?.name,
        },
        {
          label: t("subscriber.account_number"),
          value: data?.seller?.accountNumber,
        },
      ],
    },
    {
      id: data?.buyer.id,
      title: t("approval.seasonal_water_assignments.to_subscriber"),
      body: [
        {
          label: t("subscriber.name"),
          value: data?.buyer?.name,
        },
        {
          label: t("subscriber.account_number"),
          value: data?.buyer?.accountNumber,
        },
      ],
    },
  ];

  return (
    <>
      <Modal open={data}>
        <div className="flex flex-col">
          <div className="border-b p-4">
            <h3 className="text-lg font-bold leading-6 text-gray-900">
              {t("approval.permanent_trades.title")}
            </h3>
          </div>
          <div className="p-4">
            <dl className="grid grid-cols-2 gap-x-4 gap-y-2 sm:grid-cols-1 mb-4">
              {details.map(i =>
                i.show ? (
                  <div
                    className="col-span-2 sm:col-span-1 flex gap-1"
                    key={i.label}
                  >
                    <dt>{i.label}:</dt>
                    <dd className="text-gray-500">{i.value}</dd>
                  </div>
                ) : null,
              )}
            </dl>

            <div className="flex gap-2">
              {subscribers.map(s => (
                <a
                  key={"modal--subscriber--" + s.id}
                  href={`/polestar/subscribers/${s.id}`}
                  className="group w-1/2 border rounded-lg p-2 flex flex-col gap-3"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h3 className="flex flex-row items-center gap-1.5 font-semibold">
                    {s.title}
                    <ArrowTopRightOnSquareIcon className="w-4 h-4 opacity-50 group-hover:opacity-100 transition-opacity" />
                  </h3>
                  <dl className="text-sm grid grid-cols-2 gap-x-4 gap-y-2 sm:grid-cols-1">
                    {s.body.map(i => (
                      <div
                        className="col-span-2 sm:col-span-1 flex gap-1"
                        key={i.label}
                      >
                        <dt>{i.label}:</dt>
                        <dd className="text-gray-500">{i.value}</dd>
                      </div>
                    ))}
                  </dl>
                </a>
              ))}
            </div>
          </div>

          {data?.evidences?.length ? (
            <EvidenceList data={data.evidences} />
          ) : null}

          {actionButtons ? (
            <div className="flex justify-end gap-3 p-4 border-t text-sm">
              {actionButtons}
            </div>
          ) : (
            <ApplicationActionButtons
              status={data?.status}
              onApprove={() => setConfirmationModal("acknowledge")}
              onCancel={() => {
                setConfirmationModal(undefined);
                onClose();
              }}
              onReject={() => setConfirmationModal("reject")}
              onRequireMoreInfo={() => setConfirmationModal("request_info")}
            />
          )}
        </div>
      </Modal>

      {confirmModals.map((i, index) => (
        <ConfirmModal
          open={i.open}
          confirmText={i.confirmText}
          onConfirm={handleSave}
          onClose={handleCancelConfirmation}
          isSubmitting={isSubmitting}
          key={`confirm-modal-${index}`}
          disabled={!isValidBillings}
        >
          {i.description}
        </ConfirmModal>
      ))}
    </>
  );
};

export default PermanentTransferModal;
